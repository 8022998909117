import React, { useMemo, useState } from 'react';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Divider, Grid, Link } from '@mui/material';
import clsx from 'clsx';

import { CollapsibleWidget, CreateUpdateRuleModal, ModalComponent, NSelect, SadStates, WidgetLoadingState, WidgetNoResultsState } from 'components';
import { orderPriceRulesByPriority, sortOptions } from 'helpers';
import { usePricingRulesEditorConfigQuery } from 'services';
import { ActionLog, ActionType, ContexValueType, QuoteExecutionLogData, Rule, RulesetExecutionLog } from 'types';

import { useStyles } from './RulesExecutionLog.css';
import { CURRENCY_TYPE, CURRENCY_TYPE_LABEL, QUOTE_RULE_EXECUTION_FILTER_OPTIONS, RULE_EXECUTION_FILTER } from 'enums';

type RulesExecutionLogProps = {
  ruleset_execution_log: RulesetExecutionLog<QuoteExecutionLogData>,
};

export const RulesExecutionLog = ({ruleset_execution_log} : RulesExecutionLogProps) => {
  const classes = useStyles();

  const {rule_action_logs, ruleset, triggered_rules} = ruleset_execution_log || {};
  const orderedRulset = ruleset ? orderPriceRulesByPriority(ruleset) : null;
  const [ selectedRule, setSelectedRule ] = useState<Rule>(null);

  const { isError, isLoading, isSuccess, data } = usePricingRulesEditorConfigQuery();
  const { data: ruleEditorConfiguration } = data || {};
  const [ orderingFilter, setOrderingFilter ] = useState(RULE_EXECUTION_FILTER.ALL);

  const operandMap: Map<string, string> = useMemo(() => {
    return (ruleEditorConfiguration?.operands ?? []).reduce((map, operand) => {
      map.set(operand.name, operand.display_name);
      return map;
    }, new Map<string, string>());

  }, [ruleEditorConfiguration]);

  const getActionDescription = (actionLog: ActionLog) => {

    const formatValue = (value: ContexValueType) => {
      if (typeof value === 'number') {
        value = Math.round(value);
      }
      return value;
    };

    if (actionLog === null) {
      return '';
    }
    let displayName = 'Unknown Action';
    const context_change = actionLog.context_change;
    switch (actionLog.action_type) {
      case ActionType.SETTER:
        displayName = `Set ${operandMap.get(context_change?.field_name) || context_change?.field_name} to ${CURRENCY_TYPE_LABEL[ruleset_execution_log.context.currency as CURRENCY_TYPE]}${formatValue(context_change?.new_value)}`;
        break;
      case ActionType.STOP_RULE_GROUP_EXECUTION:
        displayName = 'Rule group execution ended';
        break;
      case ActionType.STOP_RULESET_EXECUTION:
        displayName = 'Rule execution ended';
        break;
      case ActionType.STOP_RULESET_EXECUTION_NO_REPLY:
        displayName = 'No Reply';
        break;
      case ActionType.STOP_RULESET_EXECUTION_REJECT:
        displayName = 'Reject';
        break;
    }
    return displayName;
  };

  const getTriggredInfo = (rule: Rule) => {
    let actionDescription = '/';
    let triggredIcon = faTimesCircle;

    for (const item of triggered_rules) {
      if (item as unknown as string === rule.id) {
        if (rule_action_logs && rule_action_logs[rule.id]) {
          if (rule_action_logs[rule.id].length === 0) {
            actionDescription = getActionDescription(rule_action_logs[rule.id][0]);
          } else {
            actionDescription = rule_action_logs[rule.id]?.map((rule_action_log) => (
              getActionDescription(rule_action_log)
            )).join(', ');
          }
        } else {
          actionDescription = 'No rule action logs avalable';
        }
        triggredIcon = faCheckCircle;
        break;
      }
    }

    return (
      <Grid
        container spacing={1}
        direction='row'
        className={classes.container}>
        <Grid item xs={7}>{actionDescription}</Grid>
        <Grid item xs={5} className={classes.alignRight}><FontAwesomeIcon icon={triggredIcon} className={clsx({
          [classes.icon]: true,
          [classes.triggered]: triggredIcon === faCheckCircle,
          [classes.notTriggered]: triggredIcon === faTimesCircle,
        })}/>
        </Grid>
      </Grid>
    );
  };

  const filteredRules = useMemo(() => {
    if (!orderedRulset) {
      return [];
    }
    if (orderingFilter === RULE_EXECUTION_FILTER.EXECUTED) {
      return orderedRulset.filter(rule =>
        triggered_rules.some(item => (item as unknown as string) === rule.id)
      );
    } else {
      return orderedRulset;
    }
  }, [ orderedRulset, orderingFilter, triggered_rules ]);

  return (
    <>
      <CollapsibleWidget headerTitle='Rules'>
        <SadStates states={[
          {
            when: orderedRulset === null,
            render: () => <WidgetNoResultsState message='No rules available.'/>
          },
          {
            when: isSuccess && Object.keys(ruleEditorConfiguration).length === 0,
            render: () => <WidgetNoResultsState message='No rules editor config available.'/>
          },
          {
            when: isError,
            render: () => <WidgetNoResultsState message='Something went wrong when trying to get rules editor config.'/>
          },
        ]}>
          <Grid
            container spacing={1}
            direction='row'
            className={clsx({
              [classes.container]: true,
              [classes.secondaryText]: true,
            })}>
            <Grid item xs={4}>Name</Grid>
            <Grid item xs={4}>Action</Grid>
            <Grid item xs={4} className={classes.alignRight}>
              <NSelect
                options={sortOptions(QUOTE_RULE_EXECUTION_FILTER_OPTIONS)}
                onChange={(values) => {
                  setOrderingFilter(values as RULE_EXECUTION_FILTER);
                }}
                selectedValues={orderingFilter}
                className={classes.selectItem}
                variant='standard'/>
            </Grid>
          </Grid>
          <Divider/>
          <SadStates states={[
            {
              when: isLoading,
              render: () => <WidgetLoadingState/>
            },
          ]}>
            <Grid className={classes.scrollableContent}>
              {
                filteredRules?.length ? (
                  filteredRules.map((rule) => (
                    <div key={rule.id}>
                      <Grid container spacing={1} direction='row' className={classes.container}>
                        <Grid item xs={4} className={classes.text}>
                          <Link onClick={() => setSelectedRule(rule)} className={classes.link} underline='none'>{rule.name}</Link>
                        </Grid>
                        <Grid item xs={8}>
                          {getTriggredInfo(rule)}
                        </Grid>
                      </Grid>
                      <Divider />
                    </div>
                  ))
                ) :
                  <WidgetNoResultsState message='No executed rules available.' />
              }
            </Grid>
          </SadStates>
        </SadStates>
      </CollapsibleWidget>
      <ModalComponent
        message={`Show Rule \u2022 ${selectedRule?.name ?? ''}`}
        isOpen={!!selectedRule}
        modalWidth='xl'
        onCancel={() => {
          setSelectedRule(null);
        }}
        disableEnforceFocus={true}>
        {<CreateUpdateRuleModal rules={orderedRulset} rule={selectedRule} ruleEditorConfiguration={ruleEditorConfiguration} onCancel={() => setSelectedRule(null)} />}
      </ModalComponent>
    </>
  );
};