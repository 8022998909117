import { makeStyles } from '@material-ui/core/styles';
import { ThemeDefault } from 'types';

export const useStyles = makeStyles((theme: ThemeDefault) => ({
  formControl: {
    borderRadius: '0.5rem',
    width: '100%',
    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: `1px solid ${theme.palette.primary.primary700}`,
    },
    '& :hover .MuiOutlinedInput-notchedOutline': {
      border: `1px solid ${theme.palette.primary.primary700}`,
    },
    '& > div > div.MuiOutlinedInput-input': {
      ...theme.typography.bodyRegularS,
      color: theme.palette.neutral.neutral700,
      padding: '0.5rem 3rem 0.5rem 1rem',
    },
    '& > div > .MuiSelect-iconOutlined': {
      right: 18
    },
    '& .MuiSelect-select:focus': {
      backgroundColor: theme.palette.neutral.neutral0,
    },
  },
  errorMsg: {
    ...theme.typography.bodyRegularXS,
    color: theme.palette.error.main,
  },
}));