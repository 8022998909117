import React, { FC, useState } from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import { NSelect } from 'components';
import { Literal, ExpressionEditorBaseProps, Option } from 'types';
import SimpleConfirmationModal from 'components/Modals/SimpleConfirmationModal';
import { initLiteral } from 'services/ruleEngine';

import expressionEditorStyles from 'components/RuleEngine/PermittedValuesEditor.css';

interface PermittedValuesEditorProps extends ExpressionEditorBaseProps {
  actionName: string;
  expression: Literal;
}

const PermittedValuesEditor: FC<PermittedValuesEditorProps> = ({ actionName, resultType, config, expression: ex, onUpdate, isReadOnly = false}) => {
  const styles = expressionEditorStyles();
  const choices : Record<string, string|number> = config.actions.find((action) => action.name === actionName)?.config?.choices ?? {};

  const options : Option[] = Object.entries(choices).map(([ key, value ]) => {
    return { id: value, label: key };
  }, {}).sort((a, b) => a.label.localeCompare(b.label));

  const [ isResetModalOpen, setIsResetModalOpen ] = useState(false);

  const onNodeReset = () => {
    const defaultValue = options.length > 0 ? options[0].id : null;
    onUpdate(initLiteral(resultType, 1, defaultValue) as Literal);
    setIsResetModalOpen(false);
  };

  const onChoiceChange = (choice: string|number) => {
    const updatedExpression = { ...ex, value: choice };
    onUpdate(updatedExpression);
  };

  const resetAction = (
    <Tooltip title='Reset Node'>
      <IconButton onClick={() => setIsResetModalOpen(true)} disabled={isReadOnly}>
        <ClearIcon />
      </IconButton>
    </Tooltip>
  );

  const subheader = (
    <div className={styles['expression-node-wrapper__subheader']}>
      <NSelect
        className={styles['expression-node-wrapper__header-control']}
        selectedValues={ex.value as string|number}
        onChange={(choice: string|number) => onChoiceChange(choice)}
        options={options}
        placeholder='Select a choice'
        readOnly={isReadOnly}/>
    </div>
  );

  return (
    <Card className={styles['expression-node-wrapper']}>
      <CardHeader
        className={styles['expression-node-wrapper__header']}
        action={resetAction}
        subheader={subheader} />
      <SimpleConfirmationModal
        isOpen={isResetModalOpen}
        title='Are you sure you want to reset the expression?'
        confirmLabel='Yes'
        rejectLabel='No'
        onConfirm={onNodeReset}
        onReject={() => setIsResetModalOpen(false)} />
    </Card>
  );
};

export default PermittedValuesEditor;
