export enum SHIPPER_FORM_GROUP {
  GENERAL_INFORMATION,
  CONTACT_INFORMATION,
  SHIPPER_CONFIGURATION,
}

export const SHIPPER_FORM_GROUP_LABELS: Record<number, string> = {
  [SHIPPER_FORM_GROUP.GENERAL_INFORMATION]: 'General information',
  [SHIPPER_FORM_GROUP.CONTACT_INFORMATION]: 'Contact information',
  [SHIPPER_FORM_GROUP.SHIPPER_CONFIGURATION]: 'Shipper configuration'
};
