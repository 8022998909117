import { VALIDATION_REQUIRED_MSG } from 'enums';
import * as Yup from 'yup';

export const getShipperRulesValidationSchema = () => {
  const validationSchema = Yup.object({
    context: Yup.mixed().required(VALIDATION_REQUIRED_MSG),
    shippers: Yup.array().min(1, 'At least one shipper should be selected'),
  });

  return validationSchema;
};