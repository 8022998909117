import React, { FC } from 'react';

import { NSelect } from 'components';
import { ExpressionEditorBaseProps, Option, Variable} from 'types';

import expressionEditorStyles from 'components/RuleEngine/ExpressionEditor.css';

interface VariableEditorProps extends ExpressionEditorBaseProps {
  expression?: Variable;
}

const VariableEditor: FC<VariableEditorProps> = ({ resultType, config, expression, onUpdate, isReadOnly=false}) => {
  const styles = expressionEditorStyles();

  const options: Option[] = config.operands
    .filter(v => v.is_expression_operand && v.type === resultType)
    .map(v => ({id: v.name, label: v.display_name}));
  const selectedOption = options.find(o => o.id === expression.value)?.id || '';

  const onVariableUpdate = (value: string) => {
    if (selectedOption === value) {
      return;
    }
    onUpdate({ ...expression, value });
  };

  return (
    <NSelect
      selectedValues={selectedOption}
      onChange={onVariableUpdate}
      options={options}
      className={styles['expression-node-wrapper__header-control']}
      placeholder='Choose Variable'
      readOnly={isReadOnly}/>
  );
};

export default VariableEditor;
