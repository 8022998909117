import React from 'react';

import { Grid, MenuItem, Select } from '@material-ui/core';
import { Typography } from '@mui/material';

import { SelectField } from 'components';
import { COST_TYPE, COST_TYPE_LABELS, QUOTE_SERVICE_TYPE, QUOTE_SERVICE_TYPE_LABELS } from 'enums';
import { MenuProps } from 'types';

import { useStyles } from './fields.css';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

export const ServiceTypeField = () => {
  const services = Object.entries(QUOTE_SERVICE_TYPE_LABELS).map(service => {
    return { id: service[0], label: service[1] };
  }).sort((a, b) => a.label.localeCompare(b.label));

  return (
    <SelectField
      id='service_type'
      label='Service Type'
      selectedValues={`${QUOTE_SERVICE_TYPE.TL}`}
      options={services} />
  );
};

type CostTypeFieldProps = {
  defaultValue: string,
  costType: COST_TYPE,
  setCostType: (costType: COST_TYPE) => void,
  setFieldValue: (field: string, value: COST_TYPE, shouldValidate?: boolean) => void
}

export const CostTypeField = ({ defaultValue, costType, setCostType, setFieldValue }: CostTypeFieldProps) => {
  const classes = useStyles();

  const costTypes = Object.entries(COST_TYPE_LABELS).map(cost => {
    return { id: cost[0], label: cost[1] };
  }).sort((a, b) => a.label.localeCompare(b.label));

  const menuProps: MenuProps = {
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left'
    },
    autoFocus: true,
    getContentAnchorEl: null,
    classes: {
      paper: classes.selectPaper
    }
  };

  return (
    <>
      <Typography className={classes.title}>Cost Type *</Typography>
      <Grid className={classes.selectContainer}>
        <Select
          variant='standard'
          disableUnderline
          id='cost_type'
          defaultValue={defaultValue}
          value={costType}
          onChange={(event) => {
            setCostType(event.target.value as COST_TYPE);
            setFieldValue('cost_type', Number(event.target.value));
          }}
          IconComponent={KeyboardArrowDownIcon}
          className={classes.select}
          MenuProps={menuProps}>
          {costTypes.map(item => (
            <MenuItem key={item.id} value={item.id}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Typography> &nbsp; </Typography> {/* Reserve error message space */}
    </>
  );
};
