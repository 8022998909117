import { makeStyles } from '@material-ui/core/styles';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

import { scrollStyle } from 'src/themeDefault';
import { ThemeDefault } from 'types';

export const useStyles = makeStyles((theme: ThemeDefault) => ({
  scrollableContent: {
    ...scrollStyle as CSSProperties,
    maxHeight: '12.25rem',
    overflowX: 'scroll',
    overflowY: 'scroll',
  },
  record: {
    marginBottom: '0.625rem'
  },
  logData: {
    ...theme.typography.bodyRegularS,
    color: theme.palette.common.black,
    fontStyle: 'normal',
    lineHeight: '1.25rem',
    wordWrap: 'break-word',
  },
  dataTimeData: {
    color: theme.palette.neutral.neutral400,
    marginRight: '0.2rem'
  }
}));