import React, { ReactNode } from 'react';

export const isObject = (value: unknown): value is Record<string, unknown> => {
  return typeof value === 'object' && value !== null;
};

export const isReactNode = (value: unknown): value is ReactNode => {
  return (
    typeof value === 'string' ||
    typeof value === 'number' ||
    typeof value === 'boolean' ||
    value === null ||
    React.isValidElement(value) ||
    (Array.isArray(value) && value.every(isReactNode))
  );
};

export const isArray = (value: unknown): value is unknown[] => {
  return Array.isArray(value);
};

export const isEmpty = (value: unknown): value is null | undefined | '' => {
  return value === null || value === undefined || value === '';
};
