import React, { useRef, useState } from 'react';
import { DragDropContext, Draggable, DropResult, Droppable } from 'react-beautiful-dnd';
import { useQueryClient } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  FormHelperText,
  Grid
} from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import AddIcon from '@material-ui/icons/Add';
import CheckBoxOutlineBlankTwoToneIcon from '@mui/icons-material/CheckBoxOutlineBlankTwoTone';
import { AxiosResponse } from 'axios';
import clsx from 'clsx';
import { addBusinessDays } from 'date-fns';
import { FieldArray, FieldArrayRenderProps, FormikErrors, FormikProps } from 'formik';

import {
  ActionButton,
  AutocompleteFilter,
  DatePickerField,
  CollapsibleButton,
  FormModalContent,
  InputField,
  PageLoadingState,
  SadStates,
  SelectField
} from 'components';
import { EQUIPMENT_LABEL, QUOTE_REQUEST_SOURCES, REEFER, STOP_ACTIVITY_TYPES } from 'enums';
import { getDateFromDatetime, getDateWithTZOffset, getDatetimeStamp } from 'helpers';
import {
  useGetCustomersQuery,
  useGetEquipmentTypesQuery,
  useQuoteCreateMutation,
  useQuoteQuery,
  useQuoteUpdateMutation
} from 'services';
import { Customer, Equipment, FormQuote, InitialStop, Option, Params, QueryResult, Stop, StoppingPoint } from 'types';
import { getValidationSchema } from 'validations';
import { StopDisplay } from './StopDisplay';

import { useStyles } from './CreateUpdateQuoteForm.css';

export const getEquipmentByName = (equipmentData : Equipment[] = [], name: string) => {
  return equipmentData.find(equipment => equipment.type === name);
};

type CreateUpdateQuoteFormProps = {
  isEditable?: boolean,
  onCancel: () => void,
};

export const CreateUpdateQuoteForm = ({ isEditable = false, onCancel }: CreateUpdateQuoteFormProps) => {
  const classes = useStyles();
  const { id } = useParams<Params>();
  const quoteId = Number(id);

  const queryClient = useQueryClient();
  const formRef = useRef<FormikProps<FormQuote>>();
  const history = useHistory();
  const [ showMoreOptions, setShowMoreOptions ] = useState<boolean>(false);
  const [ isSubmitting, setIsSubmitting ] = useState<boolean>(true);

  const { isLoading: isLoadingQuote, isSuccess: isQuoteSuccess, data: quoteData} = useQuoteQuery(quoteId);
  const { data: quote } = quoteData || {};
  isEditable = Boolean(quote);

  const { isLoading: isLoadingCustomer, isSuccess: isCustomerSuccess, data: customerData }: QueryResult<AxiosResponse<Customer[]>> = useGetCustomersQuery();
  const customers = customerData?.data?.map((c) => {
    return { id: c.id, label: c.customer_name };
  }) || [];

  const { isLoading: isLoadingEquipment, isSuccess: isEquipmentSuccess, data: equipmentData } = useGetEquipmentTypesQuery();
  const equipments = equipmentData?.data?.map((eq) => {
    return { id: eq.id, label: eq.type };
  }) || [];

  const sources = QUOTE_REQUEST_SOURCES.filter(s => s.isActive).map(crs => {
    return { id: crs.value, label: crs.name };
  }).sort((a, b) => a.label.localeCompare(b.label));

  const reeferEquipmentTypeId = getEquipmentByName(equipmentData?.data, REEFER)?.id;

  const { mutate: createQuote, isLoading: isQuoteCreating } = useQuoteCreateMutation(queryClient, {
    onSuccess: (response) => {
      history.push(`/quotes/${response.data.id}`);
      toast.success('Quote successfully created');
    },
    onError: (error) => {
      const shipperErr = error.response.data.shipper_quote_id?.[0];
      shipperErr && formRef.current.setFieldError('shipper_quote_id', shipperErr);
      toast.error('Quote creation failed');
    }
  });

  const { mutate: updateQuote, isLoading: isQuoteUpdating } = useQuoteUpdateMutation(queryClient, {
    onSuccess: () => {
      onCancel();
      toast.success('Quote has been successfully updated!');
    },
    onError: (error) => {
      const shipperErr = error.response.data.shipper_quote_id?.[0];
      shipperErr && formRef.current.setFieldError('shipper_quote_id', shipperErr);
      toast.error('Quote unsuccessfully updated');
    }
  });

  const getDefaultRequestSource = (source: number) => {
    return !source || !QUOTE_REQUEST_SOURCES.find(s => s.value === source).isActive ? '' : source;
  };

  const handleOnDragEnd = (fields: FieldArrayRenderProps, result: DropResult, errors: FormikErrors<FormQuote>, setErrors: (errors: FormikErrors<FormQuote>) => void) => {
    if (!result.destination || !result.source) {
      return;
    }
    fields.move(result.source.index, result.destination.index);
    setErrors({...errors});
  };

  const handleNewStop = (type: number, fields: FieldArrayRenderProps, values: FormQuote) => {
    const maxId = values.lane.stops.length === 0 ? 0 : Math.max(...values.lane.stops.map((stop: Stop | InitialStop) => stop.id));
    fields.push({
      id: maxId + 1,
      activity_type: type,
      drop_trailer: false,
      address: ''});
  };

  const initialValues : FormQuote = {
    customer: '',
    quoteRequestSource: '',
    pickupDate: getDatetimeStamp(1),
    deliveryDate: getDatetimeStamp(2),
    effectiveDate: getDatetimeStamp(0),
    expirationDate: addBusinessDays(new Date(), 3),
    equipment: equipments.find((e: Option) => e.label === EQUIPMENT_LABEL.DRY_VAN)?.id,
    weight: null,
    commodity: '',
    pallets: null,
    costType: null,
    shipper_quote_id: '',
    custom_fields: {
      min_temp: '',
      max_temp: ''
    },
    team: false,
    notes: '',
    hazmat: false,
    lane: {
      stops: [{id: 1, activity_type: STOP_ACTIVITY_TYPES.PICKUP, address: '', drop_trailer: false}, {id: 2, activity_type: STOP_ACTIVITY_TYPES.DELIVERY, address: '', drop_trailer: false}]
    }
  };

  const getUpdatedInitialValues = () => {
    if (quote) {
      quote.lane.stops.sort((a: StoppingPoint, b: StoppingPoint) => a.order - b.order);
      const lastId = quote.lane.stops.length-1;
      return {
        customer: quote.customer,
        quoteRequestSource: quote.request_source,
        pickupDate: getDateWithTZOffset(quote.lane.stops[0].date),
        deliveryDate: getDateWithTZOffset(quote.lane.stops[lastId].date),
        effectiveDate: getDatetimeStamp(0),
        expirationDate: quote.expiration_date,
        equipment: quote.equipment,
        weight: quote.weight,
        commodity: quote.commodity,
        pallets: quote.pallet_count,
        costType: quote.cost_type,
        distance: quote.distance,
        shipper_quote_id: quote.shipper_quote_id,
        custom_fields: quote.custom_fields,
        team: quote.team,
        hazmat: quote.hazmat,
        lane: {
          stops: quote.lane.stops.map( (item: StoppingPoint) => {
            const country = item.location.country === 'US' ? 'USA' : 'Canada';
            return {
              id: item.order,
              activity_type: item.activity_type,
              drop_trailer: !item.live_handling,
              address: item.location.city + ', ' + item.location.state + ', ' + country,
              city: item.location.city,
              state: item.location.state,
              country: item.location.country,
              zipCode: item.location.postal_code,
              geo: {
                lat: item.location.coordinates?.lat,
                lng: item.location.coordinates?.lng
              }
            };
          })
        },
      };
    }
  };

  const onSubmit = (quote: FormQuote) => {
    const payload = {
      equipment_type: Number(quote.equipment),
      weight: quote.weight || null,
      commodity: quote.commodity,
      pallet_count: quote.pallets || null,
      cost_type: quote.costType,
      custom_fields: quote.equipment === reeferEquipmentTypeId ? quote.custom_fields : null,
      team: quote.team,
      effective_date: quote.effectiveDate || null,
      expiration_date: quote.expirationDate || null,
      customer: Number(quote.customer),
      request_source: Number(quote.quoteRequestSource) || null,
      shipper_quote_id: quote.shipper_quote_id || null,
      hazmat: quote.hazmat,
      lane: {
        stops: quote.lane.stops.map((item: Stop, index: number) => {
          let date;
          switch (index) {
            case 0:
              date = getDateFromDatetime(quote.pickupDate);
              break;
            case quote.lane.stops.length-1:
              date = getDateFromDatetime(quote.deliveryDate);
              break;
            default:
              date = null;
          }
          return {
            location: {
              city: item.city,
              state: item.state,
              postal_code: item.zipCode,
              country: item.country
            },
            activity_type: item.activity_type,
            order: index+1,
            date: date,
            live_handling: !item.drop_trailer
          };
        })
      }
    };
    isEditable ? updateQuote({ id: quoteId, payload }) : createQuote({...payload, notes: quote.notes});
    setIsSubmitting(false);
  };

  const isLoadingPage = isLoadingQuote || isLoadingEquipment || isLoadingCustomer;

  return (
    <SadStates states={[
      {
        when: isLoadingPage,
        render: () => <PageLoadingState/>
      },
    ]}>
      <FormModalContent<FormQuote>
        initialValues={(isQuoteSuccess && isCustomerSuccess && isEquipmentSuccess && isEditable) ? getUpdatedInitialValues() : initialValues}
        validationSchema={getValidationSchema(reeferEquipmentTypeId, isQuoteSuccess && quote.created_at)}
        innerRef={formRef}
        enableReinitialize={false}
        enableDivider={false}
        validateOnMount={isSubmitting}
        isActionInProgress={isQuoteCreating || isQuoteUpdating}
        primaryButtonLabel={isEditable ? 'Save changes' : 'Submit'}
        onCancel={onCancel}
        onSubmit={onSubmit}>
        {({ values, errors, setFieldValue, setErrors }) => (
          <Grid container direction='column' zeroMinWidth>
            <Grid item direction='row'>
              <FormControl className={classes.muiFormControl} error={!!errors['customer']}>
                <Grid direction='column'>
                  <AutocompleteFilter
                    label='Shipper'
                    placeholder='Choose or enter shipper partner'
                    error={errors['customer'] as string}
                    defaultValue={values.customer}
                    options={customers}
                    loading={isLoadingCustomer}
                    onChange={value => setFieldValue('customer', value)}
                    reserveErrorMsgSpace />
                </Grid>
                <Grid direction='column'>
                  <InputField label='Shipper Quote ID' id='shipper_quote_id' placeholder='Enter Shipper Quote ID' />
                </Grid>
              </FormControl>
            </Grid>
            <FieldArray name='lane.stops'
              render={fields => {
                const tempError = errors as FormikErrors<FormQuote>;
                const errorMessage = typeof tempError?.lane?.stops === 'string' ? <div className={classes.errorMsg}>{tempError.lane.stops}</div> : null;
                return (
                  <>
                    <DragDropContext onDragEnd={(result) => handleOnDragEnd(fields, result, errors, setErrors)}>
                      <Droppable droppableId='stops'>
                        { (provided) => (
                          <Grid container direction='column' spacing={3} ref={provided.innerRef} {...provided.droppableProps}>
                            {values.lane.stops.map((stop: Stop | InitialStop, index: number) => (
                              <Grid item key={stop.id} xs={12} sm={12} md={12} lg={12}>
                                <Draggable
                                  key={stop.id}
                                  draggableId={stop.id.toString()}
                                  index={index}>
                                  {(provided) => (
                                    <StopDisplay
                                      errors={errors}
                                      setErrors={setErrors}
                                      setFieldValue={setFieldValue}
                                      values={values}
                                      provided={provided}
                                      fields={fields}
                                      index={index}
                                      activity_type={stop.activity_type}/>
                                  )
                                  }
                                </Draggable>
                              </Grid>
                            ))
                            }
                            {provided.placeholder}
                          </Grid>
                        )
                        }
                      </Droppable>
                    </DragDropContext>
                    {errorMessage}
                    <Grid container justify='flex-start' className={classes.showTypes}>
                      <ActionButton
                        text='Pickup Stop'
                        variant='secondary'
                        startIcon={<AddIcon/>}
                        handleClick={() => handleNewStop(STOP_ACTIVITY_TYPES.PICKUP, fields, values)}/>
                      <ActionButton
                        text='Delivery Stop'
                        variant='secondary'
                        startIcon={<AddIcon/>}
                        handleClick={() => handleNewStop(STOP_ACTIVITY_TYPES.DELIVERY, fields, values)}/>
                    </Grid>
                    <SelectField
                      id='equipment'
                      label='Service Line'
                      selectedValues={values.equipment}
                      options={equipments} />
                    <Grid className={classes.moreOptionsContainer}>
                      <CollapsibleButton
                        className={classes.moreOptions}
                        isCollapsed={showMoreOptions}
                        showTopDivider
                        text='More Details'
                        handleOnClick={() => setShowMoreOptions(!showMoreOptions)}>
                        <Grid container direction='column' className={classes.morOptionsContainer}>
                          <Grid item direction='row' className={classes.moreOptionsRowContent}>
                            <Grid direction='column' xs={12} sm={12} md={6} lg={6} className={classes.fullWidth}>
                              <DatePickerField id='pickupDate' label='Pickup Date' />
                            </Grid>
                            <Grid direction='column' xs={12} sm={12} md={6} lg={6} className={classes.fullWidth}>
                              <DatePickerField id='deliveryDate' label='Delivery Date' />
                            </Grid>
                          </Grid>
                          <Grid item direction='row' className={classes.moreOptionsRowContent}>
                            <Grid direction='column' xs={12} sm={12} md={6} lg={6} className={classes.fullWidth}>
                              <SelectField id='quoteRequestSource' label='Source' placeholder='Choose Source' selectedValues={getDefaultRequestSource(values.quoteRequestSource as number)} options={sources} />
                            </Grid>
                            <Grid direction='column' xs={12} sm={12} md={6} lg={6} className={classes.fullWidth}>
                              <InputField label='Weight (lbs)' placeholder='Enter weight' id='weight' type='number' reserveErrorMsgSpace/>
                            </Grid>
                          </Grid>
                          <Grid item direction='row' className={classes.moreOptionsRowContent}>
                            <Grid direction='column' xs={12} sm={12} md={6} lg={6} className={classes.fullWidth}>
                              <InputField label='Commodity' placeholder='Enter commodity' id='commodity' reserveErrorMsgSpace />
                            </Grid>
                            <Grid direction='column' xs={12} sm={12} md={6} lg={6} className={classes.fullWidth}>
                              <InputField label='Pallets' placeholder='Enter number of pallets' id='pallets' type='number' reserveErrorMsgSpace/>
                            </Grid>
                          </Grid>
                          <Grid item direction='row' className={clsx(classes.moreOptionsRowContent, classes.flexBottom)} >
                            <Grid direction='column' xs={12} sm={12} md={6} lg={6} className={classes.fullWidth}>
                              <DatePickerField id='effectiveDate' label='Effective Date' />
                            </Grid>
                            <Grid direction='column' xs={12} sm={12} md={6} lg={6} className={classes.fullWidth}>
                              <DatePickerField id='expirationDate' label='Expiration Date' />
                            </Grid>
                          </Grid>
                          <Grid item direction='row' className={clsx(classes.checkbox, classes.moreOptionsRowContent, classes.flexStart, classes.marginBottom)} xs={12} sm={12} md={6} lg={6}>
                            <Grid item direction='column' xs={3} sm={3} md={3} lg={3}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={values.hazmat}
                                    onChange={e => setFieldValue('hazmat', e.target.checked)}
                                    name='hazmat'
                                    color='primary'
                                    icon={<CheckBoxOutlineBlankTwoToneIcon fontSize='small' viewBox='3 3 18 18' />} />
                                }
                                label='Hazmat' />
                            </Grid>
                            <Grid item direction='column' xs={3} sm={3} md={3} lg={3}>
                              <FormControlLabel
                                className={classes.checkbox}
                                control={
                                  <Checkbox
                                    checked={values.team}
                                    onChange={e => setFieldValue('team', e.target.checked)}
                                    name='team'
                                    color='primary'
                                    icon={<CheckBoxOutlineBlankTwoToneIcon fontSize='small' viewBox='3 3 18 18' />} />
                                }
                                label='Team' />
                            </Grid>
                            <FormHelperText>{' '}</FormHelperText> {/* reserveErrorMsgSpace for input alignment */}
                          </Grid>
                          {reeferEquipmentTypeId === values.equipment &&
                            <Grid item spacing={2} direction='row' className={classes.moreOptionsRowContent} xs={12} sm={12} md={12} lg={12}>
                              <Grid direction='column' xs={12} sm={12} md={6} lg={6} className={classes.fullWidth}>
                                <InputField label='Min temp (F)' placeholder='Enter min temperature' id='custom_fields.min_temp' type='number' reserveErrorMsgSpace />
                              </Grid>
                              <Grid direction='column' xs={12} sm={12} md={6} lg={6} className={classes.fullWidth}>
                                <InputField label='Max temp (F)' placeholder='Enter max temperature' id='custom_fields.max_temp' type='number' reserveErrorMsgSpace />
                              </Grid>
                            </Grid>
                          }
                          {!isEditable &&
                          <Grid item direction='row'>
                            <InputField label='Notes' placeholder='Leave a note...' id='notes' type='textarea' inputProps={{rows: 5}} multiline reserveErrorMsgSpace />
                          </Grid>}
                        </Grid>
                      </CollapsibleButton>
                    </Grid>
                  </>
                );
              }}/>
          </Grid>
        )}
      </FormModalContent>
    </SadStates>
  );
};