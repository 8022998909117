import React, { useCallback, useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import formatISO from 'date-fns/formatISO';
import { debounce } from 'lodash';
import { stringify } from 'qs';

import {
  FilterComponent,
  IntegrationLogModal,
  Layout,
  ModalComponent,
  TableComponent,
} from 'components';
import { FILTER_PAGE_SOURCE, FILTER_SET_SOURCE, INTEGRATION_LOGS_FILTER_CONFIG, INTEGRATION_TYPES_LABEL } from 'enums';
import { calculateNumberOfFilterChanges, generateStatusTitle, getAppliedFilters, getPageAppliedFilters, setPageAppliedFilters } from 'helpers';
import { useGetCustomersQuery, useIntegrationLogsQuery } from 'services';
import { Customer, DateRangeType, FilterParamsType, IntegrationLogFilter, QueryResult } from 'types';

import { integrationLogTableColumns } from './IntegrationLogTableConfig';
import { useStyles } from './IntegrationLogsPage.css';

const PAGE_SIZE = 10;
const statusCodes = [ 2, 200, 201, 202, 204, 4, 400, 401, 404, 409, 415, 5, 500, 502, 503 ];
const ONE_MINUTE_IN_MS = 60 * 1000;

export const IntegrationLogsPage = () => {
  const classes = useStyles();

  const appliedFiltersAndSearch = getPageAppliedFilters(FILTER_PAGE_SOURCE.INTEGRATION_LOG);

  const mapSelectedFiltersToQuery = (integrationLogsFilters: FilterParamsType, search: string) => {
    const integration = integrationLogsFilters.integration as Array<string | number>;
    const statusCode = integrationLogsFilters.statusCode as Array<string | number>;
    const customer = integrationLogsFilters.shipper as Array<string | number>;
    const { fromDate = null, toDate = null } = integrationLogsFilters.createdAt as DateRangeType;

    const formatFromDate = fromDate ? formatISO(new Date(fromDate), { representation: 'date' }) : null;
    const formatToDate = toDate ? formatISO(new Date(toDate), { representation: 'date' }) : null;

    return {
      integration: integration.includes('all') ? '' : integration.join(),
      status_code: statusCode.includes('all') ? '' : statusCode.join(),
      search,
      shipper: customer.includes('all') ? '' : customer.join(),
      created_at_after: formatFromDate,
      created_at_before: formatToDate
    };
  };

  const [ page, setPage ] = useState<number>(0);
  const [ rowsPerPage, setRowsPerPage ] = useState<number>(PAGE_SIZE);
  const [ ordering, setOrdering ] = useState<string>('');
  const [ search, setSearch ] = useState<string>(appliedFiltersAndSearch.search || '');
  const [ integrationLogsFilters, setIntegrationLogsFilters ] = useState<FilterParamsType>(getAppliedFilters(appliedFiltersAndSearch));
  const [ searchFilters, setSearchFilters ] = useState<Record<string, string>>({
    shipper: '',
  });
  const [ filter, setFilter ] = useState<IntegrationLogFilter>(mapSelectedFiltersToQuery(integrationLogsFilters, search) || {});
  const [ filterChangesNumber, setFilterChangesNumber ] = useState(0);
  const [ isIntegrationLogModalOpen, setIsIntegrationLogModalOpen ] = useState<boolean>(false);
  const [ integrationLogId, setIntegrationLogId ] = useState<number>(0);

  const { isSuccess, isError, isFetching, data } = useIntegrationLogsQuery(stringify(filter), rowsPerPage, page * rowsPerPage, ordering, { refetchInterval: ONE_MINUTE_IN_MS });
  const { data: integrationLogs } = data || {};
  const { data: customerData }: QueryResult<AxiosResponse<Customer[]>> = useGetCustomersQuery(stringify({ search: searchFilters.shipper }));

  const customers = customerData?.data?.map((c: Customer) => {
    return { id: c.id, label: c.customer_name };
  }) || [];

  const filterOptions = {
    statusCode: statusCodes.map((status: number) => ({id: status, label: generateStatusTitle(status)})),
    integration: Object.keys(INTEGRATION_TYPES_LABEL).map((key: string) => ({id: key, label: INTEGRATION_TYPES_LABEL[Number(key)]})),
    shipper: customers,
  };

  const applyFilters = useCallback(() => {
    const query = mapSelectedFiltersToQuery(integrationLogsFilters, search);
    setFilter(query);
    setPageAppliedFilters(FILTER_PAGE_SOURCE.INTEGRATION_LOG, query.search, integrationLogsFilters);
    setFilterChangesNumber(calculateNumberOfFilterChanges(INTEGRATION_LOGS_FILTER_CONFIG, integrationLogsFilters));
  }, [ integrationLogsFilters, search ]);

  const applySearch = () => {
    const query = {
      ...filter,
      search,
    };
    debounceFilters(query, appliedFiltersAndSearch);
  };

  useEffect(applyFilters, []);

  useEffect(applySearch, [search]);

  useEffect(() => {
    setPage(0);
  }, [filter]);

  const debounceFilters = useCallback(
    debounce((query, appliedFiltersAndSearch) => {
      setFilter(query);
      setPageAppliedFilters(FILTER_PAGE_SOURCE.INTEGRATION_LOG, query.search, getAppliedFilters(appliedFiltersAndSearch));
    }, 300),
    []
  );

  const rowClickHandler = (logId: number) => {
    setIsIntegrationLogModalOpen(true);
    setIntegrationLogId(logId);
  };

  return (
    <Layout title='Integration Logs Dashboard'>
      <FilterComponent
        filterPageSource={FILTER_PAGE_SOURCE.INTEGRATION_LOG}
        filterSetSource={FILTER_SET_SOURCE.INTEGRATION_LOG}
        filterConfig={INTEGRATION_LOGS_FILTER_CONFIG}
        filterOptions={filterOptions}
        filterParams={integrationLogsFilters}
        setFilterParams={setIntegrationLogsFilters}
        applyFilters={applyFilters}
        searchText={search}
        setSearchText={setSearch}
        searchParams={searchFilters}
        setSearchParams={setSearchFilters}
        filterChangesNumber={filterChangesNumber}
        setFilterChangesNumber={setFilterChangesNumber}/>
      <TableComponent
        columns={integrationLogTableColumns}
        isLoading={isFetching}
        isSuccess={isSuccess}
        isError={isError}
        tableData={integrationLogs?.results}
        areRowsSelectable={false}
        page={page}
        pageRows={rowsPerPage}
        rows={integrationLogs?.count}
        setOrdering={ordering => setOrdering(ordering)}
        defaultOrderBy={'created_at'}
        defaultOrder={'desc'}
        setPage={setPage}
        setPageRows={setRowsPerPage}
        rowClickHandler={rowClickHandler}/>
      <ModalComponent
        modalWidth='md'
        message='Integration Log Details'
        className={classes.dialogStyle}
        showCloseIcon={true}
        isOpen={isIntegrationLogModalOpen}
        onCancel={() => setIsIntegrationLogModalOpen(false)}>
        <IntegrationLogModal integrationLogId={integrationLogId}/>
      </ModalComponent>
    </Layout>
  );

};