import React from 'react';
import { Typography } from '@material-ui/core';
import clsx from 'clsx';

import { ACTIVITY_TYPES, CURRENCY_TYPE, CURRENCY_TYPE_LABEL, QUOTE_SOURCE_LABEL } from 'enums';
import { SadStates, WidgetNoResultsState } from 'components';
import { formatDateToDetailPageFormat, formatTime } from 'helpers';
import { useQuoteActivitesQuery } from 'services';
import { QuoteActivity as QuoteActivityType, QuoteDetails } from 'types';

import { useStyles } from './QuoteActivity.css';

type QuoteActivityProps = {
  quote: QuoteDetails
};

export const QuoteActivity = ({ quote } : QuoteActivityProps) => {
  const classes = useStyles();

  const { isLoading, isSuccess, isError, data: activityData }= useQuoteActivitesQuery(quote.id);
  const { data: activities } = activityData || {};

  const generateActivityMessage = (activity: QuoteActivityType) => {
    switch (activity.activity_type) {
      case ACTIVITY_TYPES.CREATE_QUOTE:
        return `Created by ${activity.user_display_name}`;
      case ACTIVITY_TYPES.UPDATE_PRICE:
        return `Updated by ${activity.user_display_name} to ${CURRENCY_TYPE_LABEL[activity.custom_fields.currency as CURRENCY_TYPE]}${Math.round(activity.custom_fields.price as number)}`;
      case ACTIVITY_TYPES.MARK_QUOTE_WON:
        return `Marked as Won by ${activity.user_display_name}`;
      case ACTIVITY_TYPES.MARK_QUOTE_LOST:
        return `Marked as Lost by ${activity.user_display_name} ${activity.custom_fields.lost_reason ? `Lost reason: ${activity.custom_fields.lost_reason}` : ''}`;
      case ACTIVITY_TYPES.MARK_QUOTE_RESPONDED:
        return `Marked as Responded by ${activity.user_display_name} ${activity.custom_fields.price ? `(quoted price: ${CURRENCY_TYPE_LABEL[activity.custom_fields.currency as CURRENCY_TYPE]}${Math.round(activity.custom_fields.price as number)})` : ''}`;
      case ACTIVITY_TYPES.MARK_QUOTE_REJECTED:
        return `Marked as Rejected by ${activity.user_display_name} ${activity.custom_fields.rejection_reason ? `Rejection reason: ${activity.custom_fields.rejection_reason}` : ''}`;
      case ACTIVITY_TYPES.RESPOND_BY_EMAIL:
        return `Quoted by ${activity.user_display_name}, sent to ${activity.custom_fields.recipient_email as string || ''}, ${activity.custom_fields.price ? `for ${CURRENCY_TYPE_LABEL[activity.custom_fields.currency as CURRENCY_TYPE]}${Math.round(activity.custom_fields.price as number)}` : ''}`;
      case ACTIVITY_TYPES.RESPOND_BY_API:
        return `Quoted by ${activity.user_display_name}, sent to ${activity.custom_fields.customer as string || ''} (${QUOTE_SOURCE_LABEL[quote.quote_source]} Shipper), ${activity.custom_fields.price ? `for ${CURRENCY_TYPE_LABEL[activity.custom_fields.currency as CURRENCY_TYPE]}${Math.round(activity.custom_fields.price as number)}` : ''}`;
      case ACTIVITY_TYPES.REJECT_BY_EMAIL:
        return `Rejected by ${activity.user_display_name}, sent to ${activity.custom_fields.recipient_email as string || ''} ${activity.custom_fields.rejection_reason ? `Rejection reason: ${activity.custom_fields.rejection_reason}` : ''}`;
      case ACTIVITY_TYPES.REJECT_BY_API:
        return `Rejected by ${activity.user_display_name}, sent to ${activity.custom_fields.customer as string || ''} (${QUOTE_SOURCE_LABEL[quote.quote_source]} Shipper) ${activity.custom_fields.rejection_reason ? `Rejection reason: ${activity.custom_fields.rejection_reason}` : ''}`;
      case ACTIVITY_TYPES.UPDATE_QUOTE:
        return `Updated by ${activity.user_display_name}`;
      case ACTIVITY_TYPES.DUPLICATE_QUOTE:
        return `Created by ${activity.user_display_name} (duplicate of ${activity.custom_fields.original_quote as string})`;
      default:
        return '';
    }
  };

  return (
    <SadStates
      states={[
        {
          when: isSuccess && activities.length === 0,
          render: () => <WidgetNoResultsState message='No activities.'/>
        },
        {
          when: isError,
          render: () => <WidgetNoResultsState message='Something went wrong when trying to get quote activities.'/>
        },
      ]}>
      <Typography className={classes.scrollableContent}>
        {isLoading && <Typography className={classes.logData}>Loading events...</Typography>}
        {activities?.map((activity: QuoteActivityType, index: number) => (
          <Typography key={index} className={clsx(classes.record, classes.logData)}>
            <span className={classes.dataTimeData}>
              {formatDateToDetailPageFormat(activity.timestamp)} at {formatTime(activity.timestamp)}
            </span>
            {generateActivityMessage(activity)}
          </Typography>
        ))}
      </Typography>
    </SadStates>
  );
};