export enum COST_TYPE {
    ALLIN,
    LINEHAUL,
    LINEHAUL_WITH_FUEL_SURCHARGE
}

export const COST_TYPE_LABELS: Record<number, string> = {
  [COST_TYPE.ALLIN]: 'All In',
  [COST_TYPE.LINEHAUL]: 'Line Haul',
  [COST_TYPE.LINEHAUL_WITH_FUEL_SURCHARGE]: 'Line Haul + Fuel Surcharge'
};

export enum CURRENCY_TYPE {
  USD = 'USD',
  CAD = 'CAD',
}

export const PAGE_TYPE: Record<string, string> = {
  CREATE: 'create',
  EDIT: 'edit',
};

export enum TIMEZONES {
  UTC = 'UTC',
  CST = 'CST',
  EST = 'EST'
}

// time zone names in the IANA time zone database format
export const TIMEZONE_NAMES : Record<string, string> = {
  [TIMEZONES.UTC]: 'Etc/UTC',
  [TIMEZONES.CST]: 'America/Chicago',
  [TIMEZONES.EST]: 'America/New_York',
};

export const CURRENCY_TYPE_LABEL : Record<string, string> = {
  [CURRENCY_TYPE.USD]: '$',
  [CURRENCY_TYPE.CAD]: 'C$',
};