import React, { useEffect, useRef, useState } from 'react';
import { Box, Checkbox, CircularProgress, ListItemText, MenuItem, Select } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { Chip, FormControl, Typography } from '@mui/material';
import clsx from 'clsx';

import { NSearch } from 'components/Filter';
import { ALL_OPTION_ID } from 'enums/constants';
import { addSelectAllOption, sortOptions } from 'helpers/filterHelpers';
import { isArray, isEmpty } from 'helpers/typeGuards';
import { MenuProps, Option } from 'types';

import { useStyles } from './NSelect.css';

type NSelectProps = {
  allOption?: string,
  className?: string,
  disablePortal?: boolean,
  disabled?: boolean,
  enableSearch?: boolean,
  label?: string,
  multiple?: boolean,
  onChange: (newValues: string | number | (string | number)[]) => void,
  options: Option[],
  placeholder?: string,
  readOnly?: boolean,
  search?: string,
  selectedValues: string | number | (string | number)[],
  setSearch?: (newText: string) => void,
  showChips?: boolean,
  variant?: 'filled' | 'outlined' | 'standard',
};

export const NSelect = ({
  allOption = '',
  className,
  disablePortal = true,
  disabled,
  enableSearch = false,
  label = '',
  multiple = false,
  onChange,
  options,
  placeholder = '',
  readOnly = false,
  search,
  setSearch,
  selectedValues = null,
  showChips = false,
  variant = 'outlined',
} : NSelectProps) => {
  const items = allOption ? addSelectAllOption(sortOptions(options), allOption) : options;
  const isMultiple = multiple && isArray(selectedValues);

  const classes = useStyles({isMultiple});
  const searchRef = useRef(null);
  const [ isSelectedAll, setIsSelectedAll ] = useState(false);

  const menuProps: MenuProps = {
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
    autoFocus: true,
    getContentAnchorEl: null,
    classes: {
      paper: classes.selectPaper,
    },
    disablePortal: disablePortal,
    elevation: 0,
  };

  useEffect(() => {
    if (isMultiple) {
      setIsSelectedAll(selectedValues.includes(ALL_OPTION_ID));
    }
  }, [selectedValues]);

  const handleChange = (newId: number | string) => {
    let newSelectedValues: string | number | (string | number)[];

    if (isMultiple) {
      const filteredValues = selectedValues.filter(value => value !== newId && value !== ALL_OPTION_ID);
      if (newId === ALL_OPTION_ID) {
        newSelectedValues = [ALL_OPTION_ID];
      } else if (selectedValues.includes(newId)) {
        newSelectedValues = filteredValues.length ? filteredValues : (allOption ? [ALL_OPTION_ID] : []);
      } else {
        const updatedValues = [ ...filteredValues, newId ];
        newSelectedValues = items.every(option => option.id === ALL_OPTION_ID || updatedValues.includes(option.id)) ? [ALL_OPTION_ID] : updatedValues;
      }
    } else {
      newSelectedValues = newId;
    }
    onChange(newSelectedValues);
  };

  const renderValue = () => {
    const selectedOptions = isMultiple
      ? items.filter(option => selectedValues.includes(option.id))
      : items.filter(option => option.id === selectedValues);

    if (isEmpty(selectedValues) || (isMultiple && selectedValues.length === 0)) {
      return <Typography className={classes.placeholderText}>{placeholder}</Typography>;
    }
    return showChips ? (
      <Box className={classes.chipContainer}>
        {selectedOptions.map((value) => (
          <Chip
            key={value.id}
            className={classes.selectedChip}
            label={value.label}
            variant='outlined'
            onMouseDown={(e) => e.stopPropagation()}
            onDelete={() => handleChange(value.id)}/>
        ))}
      </Box>
    ) : (
      selectedOptions.map((value) => (
        <Typography key={value.id} className={classes.baseText}>
          {value.label}
        </Typography>
      ))
    );
  };

  return (
    <FormControl className={clsx(classes.primary, { [classes.formControlMultiple]: isMultiple })}>
      {label && <Typography className={classes.title}>{label}</Typography>}
      <Select
        onTransitionEnd={() => {
          enableSearch && searchRef?.current?.querySelector('input').focus();
        }}
        onKeyDown={(e) => e.stopPropagation()}
        onClose={() => enableSearch && setSearch('')}
        disabled={disabled}
        displayEmpty
        multiple={isMultiple}
        value={selectedValues}
        disableUnderline
        placeholder={placeholder}
        variant={variant}
        className={clsx(isMultiple ? classes.multipleSelect : classes.singleSelect, className)}
        readOnly={readOnly}
        renderValue={renderValue}
        MenuProps={menuProps}
        IconComponent={KeyboardArrowDownIcon}>
        {enableSearch &&
        <div onKeyDown={e => e.stopPropagation()} className={classes.search} ref={searchRef}>
          <NSearch value={search} handleChange={(value) => setSearch(value)} placeholder='Search...'/>
        </div>
        }
        <div className={classes.optionsContainer}>
          {items.length > 0 ? items.map(item =>
            <MenuItem
              className={classes.selectMenuItem}
              key={item.id}
              onClick={() => handleChange(item.id)}
              selected={isMultiple ? selectedValues.includes(item.id) : selectedValues === item.id}
              value={item.id}>
              {isMultiple ?
                <>
                  <Checkbox checked={isSelectedAll || (isArray(selectedValues) ? selectedValues.includes(item.id) : selectedValues === item.id)} />
                  <ListItemText primary={item.label} />
                </> :
                item.label
              }
            </MenuItem>
          )
            :
            <MenuItem className={classes.circuralProgressStyle}><CircularProgress /></MenuItem>}
        </div>
      </Select>
    </FormControl>
  );
};
