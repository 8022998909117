import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import { Field, FieldProps } from 'formik';

import { NSelect } from 'components';
import { Option } from 'types';

import { useStyles } from './SelectField.css';

type SelectFieldProps = {
  allOption?: string,
  className?: string,
  id: string,
  label: string,
  multiple?: boolean,
  name?: string,
  onChange?: (value: string | number | (string | number)[]) => void;
  options: Option[],
  placeholder?: string,
  selectedValues?: string | number | (string | number)[];
  showChips?: boolean,
  variant?: 'filled' | 'outlined' | 'standard',
};

export const SelectField = ({ allOption, className, id, label, multiple = false, onChange, options, placeholder, selectedValues, showChips = false, variant }: SelectFieldProps) => {
  const classes = useStyles();

  return (
    <div>
      <Field name={id}>
        {({ form }: FieldProps) => {
          return (
            <FormControl error={!!form.errors[id]} className={classes.formControl}>
              <NSelect
                allOption={allOption}
                className={className}
                options={options}
                label={label}
                selectedValues={form.values[id] || selectedValues}
                onChange={(newValues) => {
                  form.setFieldValue(id, newValues);
                  if (onChange) {
                    onChange(newValues);
                  }
                }}
                placeholder={placeholder}
                multiple={multiple}
                showChips={showChips}
                variant={variant}/>
              <FormHelperText className={classes.errorMsg}>{(form.errors as Record<string, string>)[id] || ' '}</FormHelperText>
            </FormControl>
          );
        }}
      </Field>
    </div>
  );
};
