import { Box, Grid } from '@mui/material';
import React, { ReactNode, useEffect, useRef, useState } from 'react';

import { AccessControl, ActionButton, LocationSearch, NSelect, TooltipComponent } from 'components';
import { DEFAULT_FILTER_VALUES, FILTER_PAGE_SOURCE, FILTER_SET_SOURCE } from 'enums';
import { calculateIndicatorPosition, getAppliedFilters } from 'helpers';
import { FILTER_SET_PERMISSIONS } from 'permissions';
import { filterIcon, resetFilterIcon } from 'resources';
import { DateRangeType, FilterConfigType, FilterParamsType, FilterParamsValueType, FilterSet, FilterType, Location, Option } from 'types';
import { NDateRangePicker, NSearch, SaveFilterSets } from './';

import { useStyles } from './FilterComponent.css';

type FilterComponentProps = {
  filterPageSource?: FILTER_PAGE_SOURCE,
  filterSetSource?: FILTER_SET_SOURCE,
  filterConfig?: FilterConfigType[],
  filterOptions?: {[key: string]: Option[]}
  filterParams?: FilterParamsType,
  setFilterParams?: (paramValue: FilterParamsType) => void
  applyFilters?: () => void,
  showSearch?: boolean,
  showFilters?: boolean,
  searchParams?: Record<string, string>,
  setSearchParams?: (value: Record<string, string>) => void,
  searchText?: string,
  setSearchText?: (searchText: string) => void,
  children?: ReactNode,
  filterChangesNumber?: number,
  setFilterChangesNumber?: (filterChangesNumber: number) => void,
}

export const FilterComponent = ({
  filterPageSource,
  filterSetSource,
  applyFilters,
  children,
  searchText,
  setSearchText,
  filterParams,
  setFilterParams,
  filterConfig,
  filterOptions,
  searchParams = {},
  setSearchParams,
  showSearch = true,
  showFilters= true,
  filterChangesNumber,
  setFilterChangesNumber,
}: FilterComponentProps) => {
  const classes = useStyles();
  const parentRef = useRef(null);
  const childRef = useRef(null);
  const indicatorRef = useRef(null);

  const [ showFiltersMenu, setShowFiltersMenu ] = useState<boolean>(false);
  const [ reset, setReset ] = useState(false);
  const [ selectedFilterSet, setSelectedFilterSet ] = useState<FilterSet>(null);

  useEffect(() => {
    if (showFiltersMenu) {
      calculateIndicatorPosition(parentRef.current, childRef.current, indicatorRef.current);
    }
  }, [showFiltersMenu]);

  useEffect(() => {
    applyFilters?.();
    setFilterChangesNumber?.(0);
  }, [reset]);

  const setParamsProperty = (propertyName: string, value: FilterParamsValueType) => {
    setFilterParams({
      ...filterParams,
      [propertyName]: value
    });
  };

  const resetFilters = () => {
    setFilterParams(getAppliedFilters(DEFAULT_FILTER_VALUES[filterPageSource]));
    setReset(!reset);
    setSelectedFilterSet(null);
  };

  const convertToDateRange = (filterDateRange: DateRangeType) => {
    return {
      fromDate: filterDateRange.fromDate ? new Date(filterDateRange.fromDate) : filterDateRange.fromDate,
      toDate: filterDateRange.toDate ? new Date(filterDateRange.toDate) : filterDateRange.toDate,
    };
  };

  return (
    <>
      <Box ref={parentRef} className={classes.filterMainContainer}>
        {(showSearch || showFilters) &&
          <Box className={classes.searchContainer}>
            {showSearch &&
                <NSearch
                  value={searchText}
                  handleChange={(value) => setSearchText(value)}/>
            }
            {showFilters &&
              <Box ref={childRef} className={classes.filterButtonWrapper}>
                <ActionButton
                  variant='default'
                  startIcon={<img src={filterIcon}/>}
                  handleClick={() => setShowFiltersMenu(!showFiltersMenu)}
                  className={showFiltersMenu ? classes.openedFiltersButton : classes.closedFiltersButton}
                  text='Filters' />
                {
                  filterChangesNumber !== 0 &&
                    <span className={classes.filterCounter}>
                      {filterChangesNumber}
                    </span>
                }
              </Box>}
          </Box>}
        {children}
      </Box>
      {showFiltersMenu &&
        <Box className={classes.filterContainer}>
          <Box ref={indicatorRef} className={classes.filterIndicator}></Box>
          <Grid container direction='row' columnSpacing={2} rowSpacing={2} flex={1}>
            {filterConfig.map((filter, index) => {
              return (
                <Grid item xs={2} className={classes.gridItem} key={index}>
                  {
                    filter.type === FilterType.Select &&
                      <NSelect
                        enableSearch={filter.enableSearch}
                        search={searchParams[filter.paramsProperty]}
                        setSearch={(value) => setSearchParams({...searchParams, [filter.paramsProperty]: value})}
                        options={filterOptions[filter.paramsProperty]}
                        onChange={(val) => setParamsProperty(filter.paramsProperty, val as FilterParamsValueType)}
                        placeholder={filter.placeholder}
                        selectedValues={filterParams[filter.paramsProperty] as number[]}
                        className={classes.nSelect} />
                  }
                  {
                    filter.type === FilterType.MultiSelect &&
                      <NSelect
                        allOption={filter.allOption}
                        options={filterOptions[filter.paramsProperty]}
                        multiple
                        enableSearch={filter.enableSearch}
                        search={searchParams[filter.paramsProperty]}
                        setSearch={(value) => setSearchParams({...searchParams, [filter.paramsProperty]: value})}
                        placeholder={filter.placeholder}
                        onChange={(val) => setParamsProperty(filter.paramsProperty, val as FilterParamsValueType)}
                        selectedValues={filterParams[filter.paramsProperty] as number[]}
                        className={classes.nSelect}
                        showChips={true}
                        variant='standard'/>
                  }
                  {
                    filter.type === FilterType.DateRange &&
                      <NDateRangePicker
                        value={convertToDateRange(filterParams[filter.paramsProperty] as DateRangeType)}
                        setValue={(val) => setParamsProperty(filter.paramsProperty, val)}/>
                  }
                  {
                    filter.type === FilterType.LocationSearch &&
                      <LocationSearch
                        showStartIcon
                        defaultValue={(filterParams[filter.paramsProperty] as Location)?.address}
                        placeholder={filter.placeholder}
                        onSelect={(location: Location) => setParamsProperty(filter.paramsProperty, location)} />
                  }
                </Grid>
              );
            })}
            <Grid item xs={2} className={classes.filterActionsContainer}>
              <Box className={classes.filterControl}>
                <AccessControl permissions={[ FILTER_SET_PERMISSIONS.RETRIEVE, FILTER_SET_PERMISSIONS.SAVE ]}>
                  <SaveFilterSets
                    filterSource={filterSetSource}
                    filterParams={filterParams}
                    selectedFilterSet={selectedFilterSet}
                    setFilterParams={setFilterParams}
                    setSelectedFilterSet={setSelectedFilterSet}/>
                </AccessControl>
                <ActionButton
                  handleClick={applyFilters}
                  variant='secondary'
                  text='Apply Filters'/>
                <TooltipComponent title='Clear all filters'>
                  <span>
                    <ActionButton
                      handleClick={resetFilters}
                      variant='default'
                      startIcon={<img src={resetFilterIcon} className={classes.resetFilterIcon} />}
                      text=''
                      className={classes.resetFilterButton}/>
                  </span>
                </TooltipComponent>
              </Box>
            </Grid>
          </Grid>
        </Box>
      }
    </>
  );
};