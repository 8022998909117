import { makeStyles } from '@material-ui/core/styles';

import { ThemeDefault } from 'types/ThemeDefaultTypes';

export const useStyles = makeStyles((theme: ThemeDefault) => ({
  formControl: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    width: '100%',
  },
  rulesModalComponent: {
    '& .MuiDialogTitle-root': {
      padding: 0,
    },
    '& .MuiBox-root p': {
      ...theme.typography.h4,
      display: 'flex',
      flexDirection: 'column',
      padding: '0 0 3.75rem 0',
    },
  },
  select: {
    padding: '0.2rem 0 0.2rem 0'
  },
}));
