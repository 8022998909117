import React from 'react';
import { Expression, ExpressionEditorConfig, Literal, Setter, ValueType } from 'types';
import ExpressionEditor from './ExpressionEditor';
import PermittedValuesEditor from './PermittedValuesEditor';

type SetterEditorProps = {
    setterState: Setter;
    setSetterState: (setter: Setter) => void;
    config: ExpressionEditorConfig;
    isReadOnly?:boolean
};

export const SetterEditor = ({ setterState, setSetterState, config, isReadOnly = false}: SetterEditorProps) => {

  const hasChoices = !!config.actions.find((action) => action.name === setterState.name)?.config?.choices;

  const onExpresionUpdate = (expresion: Expression) => {
    const setter = {
      ...setterState
    };
    setter.action_data.expression = expresion;
    setSetterState(setter);
  };

  return hasChoices ? (
    <PermittedValuesEditor
      actionName={setterState.name}
      resultType={config.actions.find(el => el.name === setterState.name).config.result_type as ValueType}
      config={config}
      expression={setterState.action_data.expression as Literal}
      onUpdate={onExpresionUpdate}
      isReadOnly={isReadOnly}/>
  ) : (
    <ExpressionEditor
      resultType={config.actions.find(el => el.name === setterState.name).config.result_type as ValueType}
      config={config}
      expression={setterState.action_data.expression}
      onUpdate={onExpresionUpdate}
      isReadOnly={isReadOnly}/>
  );
};