import React, { useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import { ConfirmationDialog, ModalComponent, TableMenu } from 'components';
import { CUSTOMER_PERMISSIONS, FUEL_SHEET_PERMISSIONS } from 'permissions';
import { ArrowUpIcon, CloneIcon, DeleteIcon, FuelIcon, Pencil, StarIcon } from 'resources';
import { useDeleteCustomerMutation } from 'services';
import { Customer } from 'types';

import { CustomerDuplicateRulesModal } from './CustomerDuplicateRulesModal';

import { useStyles } from './CustomerTableMenu.css';

type DeleteCustomerModalProps = {
  customerId: number,
  onCancel: () => void,
  onDeleteSuccess: () => void
};

const DeleteCustomerModal = ({ customerId, onCancel, onDeleteSuccess } : DeleteCustomerModalProps) => {
  const queryClient = useQueryClient();

  const { mutate: deleteCustomer, isLoading } = useDeleteCustomerMutation(queryClient, {
    onSuccess: () => {
      onDeleteSuccess();
      onCancel();
      toast.success('Shipper successfully deleted.');
    },
    onError: (error) => {
      if (error?.response?.data) {
        toast.error(error.response.data.error);
      }
      onCancel();
    }
  });

  return (
    <ConfirmationDialog
      isActionInProgress={isLoading}
      onSubmit={() => deleteCustomer(customerId)}
      onCancel={onCancel}/>
  );
};

type CustomerTableMenuProps = {
  customer: Customer,
  onActionSuccess: () => void
};

export const CustomerTableMenu = ({ customer, onActionSuccess } : CustomerTableMenuProps) => {
  const classes = useStyles();
  const [ isDeleteCustomerModalOpen, setIsDeleteCustomerModalOpen ] = useState<boolean>(false);
  const [ isDuplicateRulesCustomerModalOpen, setIsDuplicateRulesCustomerModalOpen ] = useState<boolean>(false);
  const [ anchorEl, setAnchorEl ] = useState<HTMLElement>(null);
  const isMenuOpen = !!anchorEl;

  const editCustomerHandler = () => {
    setAnchorEl(null);
  };

  const deleteCustomerHandler = () => {
    setIsDeleteCustomerModalOpen(true);
    setAnchorEl(null);
  };

  const duplicateRulesCustomerHandler = () => {
    setIsDuplicateRulesCustomerModalOpen(true);
    setAnchorEl(null);
  };

  const items = useMemo(() => [
    {
      permissions: [CUSTOMER_PERMISSIONS.UPDATE],
      text: 'Edit',
      icon: Pencil,
      onClick: editCustomerHandler,
      urlTo: `/shippers/edit/${customer.id}`,
    },
    {
      permissions: [CUSTOMER_PERMISSIONS.PRICE_RULES_RETRIEVE],
      text: 'Quoting rules',
      icon: StarIcon,
      urlTo: `/shippers/${customer.id}/price-rules`,
    },
    {
      permissions: [CUSTOMER_PERMISSIONS.MAX_BUY_RULES_RETRIEVE],
      text: 'Max Buy rules',
      icon: ArrowUpIcon,
      urlTo: `/shippers/${customer.id}/max-buy-rules`,
    },
    {
      permissions: [CUSTOMER_PERMISSIONS.DUPLICATE_RULES],
      text: 'Duplicate rules',
      icon: CloneIcon,
      onClick: duplicateRulesCustomerHandler,
      hidden: !customer.has_price_prediction_rules && !customer.has_max_buy_price_prediction_rules,
    },
    {
      permissions: [FUEL_SHEET_PERMISSIONS.RETRIEVE],
      text: 'Fuel sheets',
      icon: FuelIcon,
      urlTo: `/shippers/${customer.id}/fuel-sheets`,
    },
    {
      permissions: [CUSTOMER_PERMISSIONS.DELETE],
      text: 'Delete',
      icon: DeleteIcon,
      onClick: deleteCustomerHandler,
    },
  ], [customer]);

  return (
    <>
      <TableMenu
        onClick={(e) => setAnchorEl(e.currentTarget)}
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={() => setAnchorEl(null)}
        items={items}/>

      <ModalComponent
        message={`Are you sure you want to delete shipper "${customer.customer_name}"?`}
        isOpen={isDeleteCustomerModalOpen}
        onCancel={() => setIsDeleteCustomerModalOpen(false)}>
        <DeleteCustomerModal customerId={customer.id} onCancel={() => setIsDeleteCustomerModalOpen(false)} onDeleteSuccess={onActionSuccess}/>
      </ModalComponent>

      <ModalComponent
        message='Duplicate rules'
        isOpen={isDuplicateRulesCustomerModalOpen}
        modalWidth='sm'
        className={classes.duplicateRulesModalComponent}
        onCancel={() => setIsDuplicateRulesCustomerModalOpen(false)}>
        <CustomerDuplicateRulesModal customer={customer} onCancel={() => setIsDuplicateRulesCustomerModalOpen(false)} onDuplicateSuccess={onActionSuccess}/>
      </ModalComponent>
    </>
  );
};
