import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { AxiosResponse } from 'axios';
import { debounce } from 'lodash';
import { stringify } from 'qs';

import {
  AccessControl,
  ActionButton,
  FilterComponent,
  Layout,
  TableComponent,
} from 'components';
import { FILTER_PAGE_SOURCE, FILTER_SET_SOURCE, WORKING_COPIES_FILTER_CONFIG } from 'enums';
import { calculateNumberOfFilterChanges, getAppliedFilters, getPageAppliedFilters, setPageAppliedFilters } from 'helpers';
import { WORKING_COPY_PERMISSIONS } from 'permissions';
import { useGetCustomersQuery, useWorkingCopiesQuery } from 'services';
import { Customer, FilterParamsType, QueryResult, WorkingCopy, WorkingCopyFilter } from 'types';
import { WorkingCopiesTableMenu } from './components/WorkingCopiesTableMenu';
import { workingCopyTableColumns } from './components/WorkingCopyTableConfig';

import { useStyles } from './WorkingCopiesPage.css';

const PAGE_SIZE = 10;

export const WorkingCopiesPage = () => {
  const classes = useStyles();
  const history = useHistory();

  const appliedFiltersAndSearch = getPageAppliedFilters(FILTER_PAGE_SOURCE.WORKING_COPY);

  const mapSelectedFiltersToQuery = (workingCopyFilters: FilterParamsType, search: string) => {
    const customer = workingCopyFilters.shipper as Array<string | number>;

    return {
      customer: customer.includes('all') ? '' : customer.join(),
      search,
    };
  };

  const [ page, setPage ] = useState<number>(0);
  const [ rowsPerPage, setRowsPerPage ] = useState<number>(PAGE_SIZE);
  const [ ordering, setOrdering ] = useState<string>('');
  const [ search, setSearch ] = useState<string>(appliedFiltersAndSearch.search || '');
  const [ workingCopyFilters, setWorkingCopyFilters ] = useState<FilterParamsType>(getAppliedFilters(appliedFiltersAndSearch));
  const [ searchFilters, setSearchFilters ] = useState<Record<string, string>>({
    shipper: '',
  });
  const [ filter, setFilter ] = useState<WorkingCopyFilter>(mapSelectedFiltersToQuery(workingCopyFilters, search) || {});
  const [ filterChangesNumber, setFilterChangesNumber ] = useState(0);

  const { isSuccess, isError, isFetching, data, refetch: refetchWorkingCopies } = useWorkingCopiesQuery(stringify(filter), rowsPerPage, page * rowsPerPage, ordering);
  const { data: workingCopies } = data || {};

  const { data: customerData }: QueryResult<AxiosResponse<Customer[]>> = useGetCustomersQuery(stringify({search: searchFilters.shipper, name_only: true}));
  const customers = customerData?.data?.map((c: Customer) => ({ id: c.id, label: c.customer_name })) || [];

  const filterOptions = {
    shipper: customers
  };

  const applyFilters = useCallback(() => {
    const query = mapSelectedFiltersToQuery(workingCopyFilters, search);
    setFilter(query);
    setPageAppliedFilters(FILTER_PAGE_SOURCE.WORKING_COPY, query.search, workingCopyFilters);
    setFilterChangesNumber(calculateNumberOfFilterChanges(WORKING_COPIES_FILTER_CONFIG, workingCopyFilters));
  }, [ workingCopyFilters, search ]);

  const applySearch = () => {
    const query = {
      ...filter,
      search,
    };
    debounceFilters(query, appliedFiltersAndSearch);
  };

  useEffect(applyFilters, []);

  useEffect(applySearch, [search]);

  useEffect(() => {
    setPage(0);
  }, [filter]);

  const debounceFilters = useCallback(
    debounce((query, appliedFiltersAndSearch) => {
      setFilter(query);
      setPageAppliedFilters(FILTER_PAGE_SOURCE.WORKING_COPY, query.search, getAppliedFilters(appliedFiltersAndSearch));
    }, 300),
    []
  );

  return (
    <Layout title='Working Copies'>
      <FilterComponent
        filterPageSource={FILTER_PAGE_SOURCE.WORKING_COPY}
        filterSetSource={FILTER_SET_SOURCE.WORKING_COPY}
        filterConfig={WORKING_COPIES_FILTER_CONFIG}
        filterOptions={filterOptions}
        filterParams={workingCopyFilters}
        setFilterParams={setWorkingCopyFilters}
        applyFilters={applyFilters}
        searchText={search}
        setSearchText={setSearch}
        searchParams={searchFilters}
        setSearchParams={setSearchFilters}
        filterChangesNumber={filterChangesNumber}
        setFilterChangesNumber={setFilterChangesNumber}>
        <AccessControl permissions={[WORKING_COPY_PERMISSIONS.CREATE]}>
          <ActionButton
            text='Upload'
            variant='primary'
            startIcon={<FontAwesomeIcon icon={faPlus}/>}
            handleClick={() => history.push('working-copies/upload')}
            className={classes.button} />
        </AccessControl>
      </FilterComponent>
      <TableComponent
        columns={workingCopyTableColumns}
        isLoading={isFetching}
        isSuccess={isSuccess}
        isError={isError}
        tableData={workingCopies?.results}
        page={page}
        rows={workingCopies?.count}
        areRowsSelectable={false}
        defaultOrder={'desc'}
        defaultOrderBy={'created_at'}
        pageRows={rowsPerPage}
        setOrdering={ordering => setOrdering(ordering)}
        setPage={setPage}
        setPageRows={setRowsPerPage}
        renderMenu={(workingCopy: WorkingCopy) => {
          return (
            <WorkingCopiesTableMenu workingCopy={workingCopy} refetchData={refetchWorkingCopies} />
          );
        }}/>
    </Layout>
  );
};