import React from 'react';
import { Grid, Typography, Link, Box } from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Tooltip from '@material-ui/core/Tooltip';

import { CURRENCY_TYPE_LABEL, COST_TYPE_LABELS } from 'enums';
import { formatTimeToTimePassed, getPriceColor, getQuoteMargin } from 'helpers';
import { QuoteHistory as QuoteHistoryType, StoppingPoint } from 'types';

import { useStyles } from './QuoteHistoryItem.css';

type QuoteHistoryItemProps = {
  quote: QuoteHistoryType
};

export const QuoteHistoryItem = ({ quote } : QuoteHistoryItemProps) => {
  const classes = useStyles();
  const stops = quote.lane.stops.sort((a: StoppingPoint, b: StoppingPoint) => a.order - b.order);
  const roundedPrice = Math.round(quote.quoted_price);
  const origin = `${stops[0].location.city}, ${stops[0].location.state}`;
  const destination = `${stops[stops.length - 1].location.city}, ${stops[stops.length - 1].location.state}`;

  return (
    <Grid className={classes.itemContainer} container>
      <Grid item className={classes.flexContainer} xs={3}>
        <Typography className={classes.costTypeWidth} noWrap>({COST_TYPE_LABELS[quote.cost_type]})</Typography>
        <Box
          style={{ color: getPriceColor(quote.status) }}
          className={classes.historyPrice}>
          <Box>
            <Typography variant='h4' noWrap>
              {CURRENCY_TYPE_LABEL[quote.currency]}{roundedPrice || 0}
            </Typography>
            <Typography variant='h4'>
              {getQuoteMargin(quote)}
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Link
          color='inherit'
          href={`/quotes/${quote.id}`}
          target='_blank'>
          <Grid container justify='center' className={classes.linkItem}>
            <Grid item xs={4}>
              <Tooltip title={origin}>
                <Typography noWrap>{origin}</Typography>
              </Tooltip>
            </Grid>
            <Grid item className={classes.locationArrow} alignItems='center' xs={2}>
              <ArrowForwardIcon className={classes.historyArrowIcon} />
            </Grid>
            <Grid item xs={5}>
              <Tooltip title={destination}>
                <Typography noWrap>{destination}</Typography>
              </Tooltip>
            </Grid>
            <Grid className={classes.noWidth} xs={1}></Grid>
          </Grid>
        </Link>
      </Grid>
      <Grid item className={classes.flexContainer} xs={3}>
        <Tooltip title={quote?.customer_name}>
          <Typography noWrap align='left' className={classes.customerInitial}>{quote.customer_name}</Typography>
        </Tooltip>
        <Tooltip title={formatTimeToTimePassed(quote.created_at)}>
          <Typography noWrap className={classes.timePassed}>
            {formatTimeToTimePassed(quote.created_at)}
          </Typography>
        </Tooltip>
      </Grid>
    </Grid>
  );
};