import React, { useState } from 'react';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { Grid, InputAdornment, MenuItem, OutlinedInput, Select, Typography } from '@material-ui/core';
import { Stack } from '@mui/material';
import { clsx } from 'clsx';
import { Formik, FormikProps } from 'formik';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import { AccessControl, ActionButton, CostTypeField, InfoBox, InputField } from 'components';
import { SadStates } from 'components/SadStates';
import {
  COST_TYPE,
  COST_TYPE_LABELS,
  CURRENCY_TYPE,
  QUOTE_SERVICE_TYPE,
  QUOTE_SOURCE,
  QUOTE_STATUS,
  QUOTE_RULE_ENGINE_RESPONSE_STATUS,
  RULE_ENGINE_STATUS_NO_REPLY_MESSAGE,
  CURRENCY_TYPE_LABEL
} from 'enums';
import { QUOTE_PERMISSIONS } from 'permissions';
import { CheckIcon, Pencil, XMark } from 'resources';
import { useQuoteUpdatePriceMutation } from 'services';
import { MenuProps, PriceCurrency, QuoteDetails as QuoteDetailsType } from 'types';
import { getValidationSchema } from '../validation';
import { QuotePriceMarginResolver } from './QuotePriceMarginResolver';

import { useStyles } from '../QuoteDetailsPage.css';

type QuotedPriceFormProps = {
    quote: QuoteDetailsType,
    priceCurrency: PriceCurrency,
    costType: COST_TYPE,
    setCostType: (costType: COST_TYPE) => void,
    formRef: React.MutableRefObject<FormikProps<undefined>>,
    setPriceCurrency: React.Dispatch<React.SetStateAction<PriceCurrency>>
  };

export const QuotedPriceForm = ({ quote, priceCurrency, costType, setCostType, formRef, setPriceCurrency }: QuotedPriceFormProps) => {
  const classes = useStyles();
  const queryClient = useQueryClient();
  const [ isEditable, setIsEditable ] = useState<boolean>(false);
  const [isLoading] = useState<boolean>(false);

  const roundedPrice = Math.round(quote.quoted_price);
  const roundedMargin = Math.round(quote.margin);

  const { mutate: savePrice, isLoading: isQuotePriceUpdating } = useQuoteUpdatePriceMutation(queryClient, {
    onSuccess: () => {
      toast.success('Quote price updated');
    },
  });

  const handleChange = (value: string) => {
    setPriceCurrency({
      ...priceCurrency,
      price: Number(value)
    });
  };

  const quotedPriceLabel = 'Quoted Price';
  const formatQuotePriceTitle = (cost_type?: COST_TYPE) => {
    return cost_type === undefined ? quotedPriceLabel : `${quotedPriceLabel}  - ${COST_TYPE_LABELS[cost_type]}`;
  };

  const initialValues = {
    price: roundedPrice || 0,
    currency: quote.currency || CURRENCY_TYPE.USD,
    cost_type: quote.cost_type,
    service_type: QUOTE_SERVICE_TYPE.TL
  };

  const selectAdornmentMenuProps: MenuProps = {
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left'
    },
    autoFocus: true,
    getContentAnchorEl: null,
    classes: {
      paper: classes.selectPaper
    }
  };

  const getSelectAdornment = (setFieldValue: (field: string, value: unknown, shouldValidate?: boolean) => void) => (
    <Select
      variant='standard'
      disableUnderline
      value={priceCurrency.currency}
      onChange={(event) => {
        setPriceCurrency({
          ...priceCurrency,
          currency: event.target.value as string
        });
        setFieldValue('currency', event.target.value);
      }}
      IconComponent={KeyboardArrowDownIcon}
      MenuProps={selectAdornmentMenuProps}
      className={classes.selectAdornment}>
      {Object.values(CURRENCY_TYPE).map(currency => (
        <MenuItem key={currency} value={currency}>
          {currency}
        </MenuItem>
      ))}
    </Select>
  );

  return (
    quote.status === QUOTE_STATUS.OPEN || (quote.quote_source === QUOTE_SOURCE.TRANSPLACE && quote.status === QUOTE_STATUS.RESPONDED) ? (
      <Formik
        onSubmit={() => null}
        initialValues={initialValues}
        validateOnChange
        validateOnMount
        enableReinitialize
        validationSchema={getValidationSchema}
        innerRef={formRef}>
        {({dirty, isValid, values, resetForm, setFieldValue}) => (
          <>
            <Stack direction='row' spacing={2} className={classes.titleContainer} >
              <Typography variant='h4' >{formatQuotePriceTitle()}</Typography>
              {quote?.pricing_rules_computed_data?.response_status === QUOTE_RULE_ENGINE_RESPONSE_STATUS.NO_REPLY &&
                <InfoBox message={RULE_ENGINE_STATUS_NO_REPLY_MESSAGE} />}
            </Stack>
            <Stack direction='row' spacing={2} className={classes.quotedPriceFormContainer}>
              <Grid item direction='column' xs={12} sm={5} md={5} lg={5}>
                <InputField
                  id='price'
                  label={`${quotedPriceLabel} *`}
                  type='number'
                  onChange={(v) => {
                    handleChange(v);
                    setFieldValue('price', Number(v));
                  }}
                  startIcon={CURRENCY_TYPE_LABEL[priceCurrency.currency]}
                  endIcon={<InputAdornment position='end'>{getSelectAdornment(setFieldValue)}</InputAdornment>}
                  className={clsx(classes.currencyInput, classes.currencyInputSize)}
                  reserveErrorMsgSpace={true} />
              </Grid>
              <Stack direction='column' className={classes.selectWrapper}>
                <CostTypeField defaultValue={`${values.cost_type}`} costType={costType} setCostType={setCostType} setFieldValue={setFieldValue} />
              </Stack>
              <Stack direction='column' className={classes.quotePriceButtonWrapper}>
                <Stack direction='row' spacing={1}>
                  <ActionButton
                    startIcon={<CheckIcon />}
                    isLoading={isQuotePriceUpdating}
                    className={classes.actionBtnSize}
                    disabled={!(isValid && dirty) || isQuotePriceUpdating}
                    handleClick={() => savePrice({
                      id: quote.id,
                      price: priceCurrency.price === roundedPrice ? quote.quoted_price : priceCurrency.price,
                      currency: priceCurrency.currency,
                      cost_type: costType })} />
                  <ActionButton
                    startIcon={<XMark />}
                    variant='secondary'
                    className={classes.actionBtnSize}
                    disabled={!dirty || isQuotePriceUpdating}
                    handleClick={() => {
                      setPriceCurrency({
                        price: initialValues['price'],
                        currency: initialValues['currency']
                      });
                      setCostType(quote.cost_type);
                      resetForm();
                    }} />
                </Stack>
              </Stack>
            </Stack>
          </>
        )}
      </Formik>
    ) : (
      isEditable ?
        <QuotePriceMarginResolver quote={quote} formRef={formRef} setIsEditable={setIsEditable} priceCurrency={priceCurrency} setPriceCurrency={setPriceCurrency} /> :
        <SadStates states={[
          {
            when: isLoading,
            render: function renderLoadingState() {
              return <p>Loading...</p>;
            }
          }
        ]}>
          <Grid container spacing={2} alignItems='center' className={classes.priceContainerWidth}>
            <Grid item justify='flex-start'>
              <Typography variant='h4' className={classes.title}>{formatQuotePriceTitle(quote.cost_type)}</Typography>
              <Stack direction='row' spacing={3} justifyContent='flex-start' alignItems='center' className={clsx(classes.currencyContainer, classes.currencyInputSize)}>
                <Stack justifyContent='flex-start' alignItems='center'>
                  <OutlinedInput
                    id='price'
                    type='number'
                    readOnly
                    value={roundedPrice}
                    startAdornment={CURRENCY_TYPE_LABEL[quote.currency]}
                    className={clsx({
                      [classes.currencyInput]: true,
                      [classes.wonCurrency]: quote.status === QUOTE_STATUS.WON,
                      [classes.lostCurrency]: quote.status === QUOTE_STATUS.LOST || quote.status === QUOTE_STATUS.EXPIRED,
                    })} />
                </Stack>
                {quote.status === QUOTE_STATUS.WON &&
                  <Stack justifyContent='flex-start' alignItems='center'>
                    <OutlinedInput
                      id='margin'
                      type='number'
                      readOnly
                      value={roundedMargin}
                      startAdornment={CURRENCY_TYPE_LABEL[quote.currency]}
                      className={clsx(classes.currencyInput, classes.wonCurrency)} />
                  </Stack>
                }

                {quote.status === QUOTE_STATUS.RESPONDED && (
                  <Grid item onClick={() => setIsEditable(true)}>
                    <Pencil className={classes.priceEditIcon}/>
                  </Grid>
                )}
                {quote.status === QUOTE_STATUS.WON && (
                  <AccessControl permissions={[QUOTE_PERMISSIONS.UPDATE_MARGIN]}>
                    <Grid item onClick={() => setIsEditable(true)}>
                      <Pencil className={classes.priceEditIcon}/>
                    </Grid>
                  </AccessControl>
                )}
              </Stack>
            </Grid>
          </Grid>
        </SadStates>
    )
  );
};