import { makeStyles } from '@material-ui/core/styles';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

import { scrollStyle } from 'src/themeDefault';
import { ThemeDefault } from 'types';
type Props = {
  isMultiple: boolean;
};

export const useStyles = makeStyles<ThemeDefault, Props>((theme: ThemeDefault) => ({
  primary: {
    '& .MuiInputBase-root': {
      '& .MuiSelect-root': {
        borderColor: 'transparent',
      },
      '& .MuiPaper-root': {
        borderColor: theme.palette.primary.primary700,
      },
    },
  },
  singleSelect: {
    ...theme.typography.bodyRegularS,
    backgroundColor: theme.palette.common.white,
    borderRadius: '0.5rem',
    color: theme.palette.neutral.neutral700,
    '& .MuiOutlinedInput-notchedOutline': {
      border: `0.063rem solid ${theme.palette.primary.primary200}`,
      borderRadius: '0.5rem',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.primary.primary700,
    },
    '&.MuiOutlinedInput-root.Mui-focused': {
      '& > fieldset': {
        border: `0.063rem solid ${theme.palette.primary.primary700}`,
      },
    },
    '& .MuiSelect-root': {
      ...theme.typography.bodyRegularS,
      backgroundColor: 'transparent',
      padding: '0.5rem 2rem 0.5rem 1rem',
      width: '100%',
    },
    '& .MuiSelect-iconOutlined': {
      right: 0,
      margin: 'auto 0.5rem'
    },
  },
  multipleSelect: {
    border: '0.063rem solid',
    borderColor: 'transparent',
    '& .MuiOutlinedInput-notchedOutline': {
      border: `0.063rem solid ${theme.palette.primary.primary200}`,
      borderRadius: '0.5rem',
    },
    '& .MuiPaper-root': {
      border: '0.063rem solid',
      borderRadius: '0.5rem',
      marginTop: '.5rem',
      width: '10rem',
      '& .MuiList-padding': {
        padding: 0,
      },
      '& li': {
        '&[aria-selected="true"]': {
          backgroundColor: theme.palette.primary.primary100,
          color: theme.palette.primary.primary700,
        },
        '&:hover': {
          backgroundColor: theme.palette.primary.primary100,
        },
      },
    },
    '& .MuiPopover-root': {
      cursor: 'default',
      width: '100%',
    },
    '& .MuiSelect-root': {
      ...theme.typography.bodyRegularS,
      backgroundColor: theme.palette.neutral.neutral0,
      border: '0.063rem solid',
      borderRadius: '0.5rem',
      boxSizing: 'border-box',
      height: '2.5rem',
      justifyContent: 'left',
      display: 'flex',
      alignItems: 'center',
      paddingLeft: '0.5rem',
      paddingRight: '2.5rem',
      '&:hover': {
        border: `0.063rem solid ${theme.palette.primary.primary700}`,
      },
      '&:focus': {
        border: `0.063rem solid ${theme.palette.primary.primary200}`,
      },
    },
  },
  selectPaper: {
    ...theme.typography.bodyRegularS,
    ...scrollStyle as CSSProperties,
    border: `0.063rem solid ${theme.palette.primary.primary700}`,
    borderRadius: '0.5rem',
    marginTop: '0.25rem',
    maxHeight: 'calc(100vh / 2)',
    overflowY: 'scroll',
    padding: 0,
    '& .MuiListItem-root.Mui-selected': {
      backgroundColor: ({isMultiple}) => isMultiple ? 'transparent' : theme.palette.primary.primary100,
      color: ({isMultiple}) => isMultiple ? 'inherit' : theme.palette.primary.primary700,
    },
    '& .MuiCheckbox-colorSecondary.Mui-unchecked': {
      color: theme.palette.primary.primary500,
    },
    '& .MuiCheckbox-colorSecondary.Mui-checked': {
      color: theme.palette.primary.primary700,
    },
    '& .MuiListItem-button:hover': {
      backgroundColor: theme.palette.primary.primary100,
    },
    '& ul': {
      padding: 0,
    },
    '& li': {
      ...theme.typography.bodyRegularS,
      height: '2.5rem',
    },
  },
  optionsContainer: {
    ...scrollStyle as CSSProperties,
    maxHeight: '18rem',
  },
  search: {
    width: '100%',
    '& > div:first-child': {
      width: 'auto',
      marginLeft: '0.5rem',
      marginRight: '0.5rem',
      '& > div': {
        display: 'flex',
        justifyContent: 'center'
      }
    }
  },
  chipContainer: {
    display: 'flex',
    gap: '.4rem',
    height: '1.5rem',
    minWidth: '7.5rem',
    overflow: 'hidden',
    width: '100%',
    '& svg': {
      fill: theme.palette.primary.primary500,
      width: '1rem',
      cursor: 'move'
    }
  },
  selectedChip: {
    ...theme.typography.bodyRegularS,
    backgroundColor: theme.palette.neutral.neutral0,
    border: `0.063rem solid ${theme.palette.primary.primary500}`,
    borderRadius: '0.75rem',
    height: '1.5rem',
  },
  selectMenuItem: {
    ...theme.typography.bodyRegularS,
    height: '2.5rem',
    boxSizing: 'border-box',
    '& .MuiButtonBase-root': {
      padding: 0
    },
    '& svg': {
      fill: theme.palette.primary.primary700,
      marginRight: '.8rem'
    },
    '& .MuiTypography-body1': {
      ...theme.typography.bodyRegularS,
    },
  },
  baseText: {
    ...theme.typography.bodyRegularS,
  },
  title: {
    ...theme.typography.bodyRegularS,
    marginBottom: '0.5rem',
  },
  placeholderText: {
    ...theme.typography.bodyRegularS,
    color: theme.palette.neutral.neutral400,
    paddingLeft: ({isMultiple}) => isMultiple ? '0.5rem' : '0',
  },
  circuralProgressStyle: {
    display: 'flex',
    justifyContent: 'center !important',
    alignItems: 'center',
    padding: 20,
    '& .MuiCircularProgress-colorPrimary': {
      color: theme.palette.primary.primary700,
    },
  },
  formControlMultiple: {
    width: '100%',
    fontSize: '1rem',
  }
}));
