import React, { useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FormControl, Grid } from '@mui/material';
import { Form, Formik } from 'formik';

import { ActionButton, InputField, ModalComponent, SelectField, UploadFile } from 'components';
import { FAILED_TO_UPLOAD_FILE_MESSAGE, FILE_CATEGORY, REGIONS, SUCCESSFULLY_UPLOADED_FILE_MESSAGE, UPLOAD_CSV_FILE_TITLE } from 'enums';
import { useImportFileMutation } from 'services';
import { Customer, FuelSheetImport, Params } from 'types';
import { getFuelSheetValidationSchema } from 'validations';

import { useCommonStyles } from 'styles';
import { useStyle } from './ImportFuelSheetModal.css';

type ImportFuelSheetModalContentProps = {
  customer: Customer,
  onUploadSuccess: () => void,
  onCancel : () => void
};

const ImportFuelSheetModalContent = ({ onCancel, customer, onUploadSuccess } : ImportFuelSheetModalContentProps) => {
  const classes = useStyle();
  const commonClasses = useCommonStyles();
  const { id } = useParams<Params>();
  const inputRef = useRef<HTMLInputElement>();
  const [ isImported, setIsImported ] = useState<boolean>(false);

  const { mutate: importFile, isLoading } = useImportFileMutation({
    onSuccess: () => {
      toast.success(SUCCESSFULLY_UPLOADED_FILE_MESSAGE);
      setIsImported(true);
      onUploadSuccess();
      onCancel();
    },
    onError: () => {
      toast.error(FAILED_TO_UPLOAD_FILE_MESSAGE);
    }
  });

  const handleImportFuelSheet = (values: FuelSheetImport) => {
    const formData = new FormData();
    formData.append('file', values.file);
    formData.append('name', values.file.name);
    formData.append('type', values.file.type);
    formData.append('category', String(FILE_CATEGORY.FUEL_SHEET));
    formData.append('region', values.region);
    formData.append('customer', id);
    formData.append('fuel_price_delay', String(values.fuel_price_delay));
    importFile(formData);
  };

  return (
    <Formik
      initialValues={{
        file: null,
        region: '',
        fuel_price_delay: customer?.default_fuel_price_delay || 0,
      }}
      validateOnMount
      validationSchema={getFuelSheetValidationSchema}
      onSubmit={handleImportFuelSheet}>
      {({ values, errors, setFieldValue, isValid }) => (
        <Form>
          <Grid>
            <Grid className={classes.content}>
              <UploadFile
                id='file'
                accept='.csv'
                className={classes.uploadInput}
                errors={errors}
                inputRef={inputRef}
                title={UPLOAD_CSV_FILE_TITLE}
                values={values}
                setFieldValue={setFieldValue} />
            </Grid>
            <Grid>
              <FormControl error={!!errors['region']} className={classes.formControl}>
                <SelectField
                  id='region'
                  className={commonClasses.selectHeight}
                  label='Region'
                  placeholder='Choose region'
                  options={REGIONS} />
              </FormControl>
            </Grid>
            <Grid>
              <FormControl error={!!errors['fuel_price_delay']} className={classes.formControl}>
                <InputField
                  reserveErrorMsgSpace
                  id='fuel_price_delay'
                  label='Fuel Price Delay'
                  onChange={(value) => setFieldValue('fuel_price_delay', value)}
                  type='number'/>
              </FormControl>
            </Grid>
            <Grid container justifyContent='flex-end' spacing={2}>
              <Grid item>
                <ActionButton variant='secondary' colorVariant='white' className={classes.buttonText} text={isImported ? 'Close' : 'Cancel'} handleClick={onCancel} />
              </Grid>
              {isImported ? null :
                <Grid item>
                  <ActionButton
                    text='Confirm'
                    disabled={!isValid || isLoading}
                    type='submit'
                    isLoading={isLoading} />
                </Grid>
              }
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

type ImportFuelSheetModalProps = {
  customer: Customer,
  onUploadSuccess: () => void,
  isModalOpen: boolean,
  onCancel: () => void
};

export const ImportFuelSheetModal = ({ isModalOpen, onCancel, customer, onUploadSuccess } : ImportFuelSheetModalProps) => {
  return (
    <ModalComponent
      modalWidth='xs'
      message='Import fuel sheet'
      isOpen={isModalOpen}
      onCancel={onCancel}>
      <ImportFuelSheetModalContent customer={customer} onCancel={onCancel} onUploadSuccess={onUploadSuccess}/>
    </ModalComponent>
  );
};
